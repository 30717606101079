import './App.css';
const logo = require('./res/images/posstruction.png');

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} alt={"Poss at work!"} />
      </header>
    </div>
  );
}

export default App;
